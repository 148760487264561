import React, { useRef, useEffect } from "react"
import { graphql } from "gatsby"
import gsap from "gsap"
import Img from "gatsby-image"
import { ScrollScene } from "scrollscene"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"

export const query = graphql`
  query getProjectContent($id: String!) {
    heroBgImg: allWordpressWpHero {
      edges {
        node {
          acf {
            project_hero {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bgImg: file(relativePath: { eq: "m6.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    projectContent: wordpressWpProject(id: { eq: $id }) {
      title
      path
      status
      template
      acf {
        hero {
          project_title
          project_subtitle
        }
        url
        summary {
          heading
          description
          image {
            id
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
        brief {
          heading
          description
          front_end
          back_end
          image {
            id
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          gallery {
            image_1 {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            image_2 {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            image_3 {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            image_4 {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Project = ({ data }) => {
  let headerContainer = useRef(null)
  let headerLogo = useRef(null)
  let headerMenu = useRef(null)

  let bannerHeading = useRef(null)
  let bannerText = useRef(null)
  let bannerBgBlock = useRef(null)
  let bannerImg = useRef(null)
  let bannerImgCover = useRef(null)
  let bannerLink = useRef(null)
  let infoImg = useRef(null)
  let infoImgCover = useRef(null)
  let infoBgBlock = useRef(null)
  let infoHeading = useRef(null)
  let infoText = useRef(null)

  useEffect(() => {
    //GSAP Animations
    const headerAnimation = gsap.timeline()

    headerAnimation
      .set(headerContainer.current, {
        visibility: "visible",
      })
      .from([headerLogo.current, headerMenu.current], {
        duration: 0.5,
        y: 10,
        autoAlpha: 0,
        ease: "power3.inOut",
        stagger: 0.2,
        delay: 2,
      })
  }, [])

  useEffect(() => {
    //GSAP Animations
    const bannerAnimation = gsap.timeline({ paused: true })
    const infoAnimation = gsap.timeline({ paused: true })
    // const galleryAnimation = gsap.timeline()

    bannerAnimation
      .from(bannerBgBlock, {
        autoAlpha: 0,
        scaleX: 0,
        duration: 0.5,
        transformOrigin: "left",
        ease: "cubic-bezier(.59,.08,.385,1)",
      })
      .from(
        bannerImgCover,
        {
          duration: 0.6,
          scaleX: 0,
          transformOrigin: "left",
          ease: "cubic-bezier(.59,.08,.385,1)",
        },
        "<"
      )
      .to(bannerImg, {
        duration: 0,
        autoAlpha: 1,
        rotation: 0.01,
      })
      .to(bannerImgCover, {
        duration: 0.6,
        scaleX: 0,
        transformOrigin: "right",
        ease: "cubic-bezier(.59,.08,.385,1)",
        rotation: 0.01,
      })
      .from(
        [bannerHeading, bannerText, bannerLink],
        {
          autoAlpha: 0,
          y: 15,
          ease: "power1.easeOut",
          rotation: 0.01,
          stagger: 0.2,
        },
        "-=0.3"
      )

    infoAnimation
      .from(infoBgBlock, {
        autoAlpha: 0,
        scaleX: 0,
        duration: 0.5,
        transformOrigin: "left",
        ease: "cubic-bezier(.59,.08,.385,1)",
      })
      .from(
        infoImgCover,
        {
          duration: 0.6,
          scaleX: 0,
          transformOrigin: "left",
          ease: "cubic-bezier(.59,.08,.385,1)",
        },
        "<"
      )
      .to(infoImg, {
        duration: 0,
        autoAlpha: 1,
        rotation: 0.01,
      })
      .to(infoImgCover, {
        duration: 0.6,
        scaleX: 0,
        transformOrigin: "right",
        ease: "cubic-bezier(.59,.08,.385,1)",
        rotation: 0.01,
      })
      .from(
        [infoHeading, infoText],
        {
          autoAlpha: 0,
          y: 15,
          ease: "power1.easeOut",
          rotation: 0.01,
          stagger: 0.2,
        },
        "-=0.3"
      )

    const bannerRevealScene = new ScrollScene({
      triggerElement: ".project-banner__heading",
      gsap: {
        timeline: bannerAnimation,
      },
      scene: {
        reverse: false,
        triggerHook: 0.85,
      },
      destroyImmediately: true,
    })

    bannerRevealScene.Scene.on("enter", function(e) {
    })

    new ScrollScene({
      triggerElement: ".project-info__heading",
      gsap: {
        timeline: infoAnimation,
      },
      scene: {
        reverse: false,
        triggerHook: 0.85,
      },
      destroyImmediately: true,
    })

    return () => {
      bannerRevealScene.destroy()
    }
  }, [])

  const heroContent = data.projectContent.acf.hero
  const summaryContent = data.projectContent.acf.summary
  const briefContent = data.projectContent.acf.brief
  const galleryContent = data.projectContent.acf.brief.gallery
  const projectHeroImg =
    data.heroBgImg.edges[0].node.acf.project_hero.localFile.childImageSharp
      .fluid
  const projectURL = data.projectContent.acf.url
  const bgImage = data.bgImg.childImageSharp.fluid
  const settings = {
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    vertical: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          arrows: false,
        },
      },
    ],
  }
  let hasGallery = false;

  return (
    <Layout
      ref={{
        headerLogo: headerLogo,
        headerMenu: headerMenu,
        headerContainer: headerContainer,
      }}
    >
      <SEO title={heroContent.project_title} />
      <Hero
        heroTitle={heroContent.project_title}
        heroSubtitle={heroContent.project_subtitle}
        heroBgImg={projectHeroImg}
        bgImg={bgImage}
      />
      <section className="section-project-banner full-width-container">
        <div className="project-banner__row fixed-width-container">
          <div className="project-banner__col project-banner__col--content">
            <h2
              ref={el => (bannerHeading = el)}
              className="heading-secondary project-banner__heading"
            >
              "{summaryContent.heading}"
            </h2>
            <p
              ref={el => (bannerText = el)}
              className="project-banner__description"
            >
              {summaryContent.description}
            </p>
            <a
              ref={el => (bannerLink = el)}
              className="project-banner__link btn-line-anim"
              target="_blank"
              href={(projectURL.length > 0) ? projectURL : "#"}
              style={{display: (projectURL.length > 0) ? 'block' : 'none' }}
            >
              View Site
            </a>
          </div>
          <div className="project-banner__col project-banner__col--img">
            <div
              ref={el => (bannerBgBlock = el)}
              className="project-banner__background-block"
            ></div>
            <div className="project-banner__img-container">
              <div
                ref={el => (bannerImg = el)}
                className="project-banner__img-wrapper"
              >
                <Img
                  fluid={summaryContent.image.localFile.childImageSharp.fluid}
                  alt=""
                  className="project-banner__img"
                />
              </div>
              <div
                ref={el => (bannerImgCover = el)}
                className="project-banner__img-cover"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-project-info full-width-container">
        <div className="project-info__row fixed-width-container">
          <div className="project-info__col project-info__col--img">
            <div
              ref={el => (infoBgBlock = el)}
              className="project-info__background-block"
            ></div>
            <div className="project-info__img-container">
              <div
                ref={el => (infoImg = el)}
                className="project-info__img-wrapper"
              >
                <Img
                  fluid={briefContent.image.localFile.childImageSharp.fluid}
                  alt=""
                  className="project-info__img"
                />
              </div>
              <div
                ref={el => (infoImgCover = el)}
                className="project-info__img-cover"
              ></div>
            </div>
          </div>
          <div className="project-info__col project-info__col--content">
            <h2
              ref={el => (infoHeading = el)}
              className="heading-secondary project-info__heading"
            >
              {briefContent.heading}
            </h2>
            <p
              ref={el => (infoText = el)}
              className="project-info__description"
            >
              <span className="project-info__label">Front-End</span>
              <span>{briefContent.front_end}</span>
              <span className="project-info__label">Back-End</span>
              <span>{briefContent.back_end}</span>
              {briefContent.description}
            </p>
          </div>
        </div>
      </section>
      {
      Object.keys(galleryContent).map((gallery,index) => {
        if ((galleryContent[gallery]) && Object.keys(galleryContent[gallery]).length > 0) {
          hasGallery = true;
        }
      })}
      {hasGallery &&
      <section className="section-project-slider fixed-wdith-container">
        <div className="project-slider">
          <Slider {...settings}>
            {((galleryContent.image_1) && Object.keys(galleryContent.image_1).length > 0) &&
            <div className="project-slider__col">
              <Img
                fluid={galleryContent.image_1.localFile.childImageSharp.fluid}
                alt=""
                className="project-slider__img project-slider__img--1"
              />
            </div>
            }
            {((galleryContent.image_2) && Object.keys(galleryContent.image_2).length > 0) &&
            <div className="project-slider__col">
              <Img
                fluid={galleryContent.image_2.localFile.childImageSharp.fluid}
                alt=""
                className="project-slider__img project-slider__img--2"
              />
            </div>
            }
            {((galleryContent.image_3) && Object.keys(galleryContent.image_3).length > 0) &&
            <div className="project-slider__col">
              <Img
                fluid={galleryContent.image_3.localFile.childImageSharp.fluid}
                alt=""
                className="project-slider__img project-slider__img--3"
              />
            </div>
            }
            {((galleryContent.image_4) && Object.keys(galleryContent.image_4).length > 0) &&
            <div className="project-slider__col">
              <Img
                fluid={galleryContent.image_4.localFile.childImageSharp.fluid}
                alt=""
                className="project-slider__img project-slider__img--4"
              />
            </div>
            }
          </Slider>
        </div>
      </section>
      }
    </Layout>
  )
}

export default Project
