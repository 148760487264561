import React, { useRef, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import gsap from "gsap"
import Img from "gatsby-image"

import bgImgWhite from "../images/zencircle_white.png"

const Hero = ({ heroTitle, heroSubtitle, heroBgImg, bgImg }) => {
  let title = useRef(null)
  let subtitle = useRef(null)
  let bgCircle = useRef(null)
  let bgLayer = useRef(null)

  const aboutHeroImg = useEffect(() => {
    console.log("hero useeffect")
    const heroAnimation = gsap.timeline()

    heroAnimation
      .from(bgCircle, {
        duration: 1.2,
        ease: "power3.inOut",
        rotation: 360,
      })
      .to(
        bgCircle,
        {
          autoAlpha: 0,
          ease: "power3.inOut",
        },
        "-=0.3"
      )
      .to(
        bgLayer,
        {
          duration: 1.3,
          scaleX: 0,
          delay: 1,
          transformOrigin: "right",
          ease: "power3.inOut",
        },
        "-=1.1"
      )
      .from(
        [title, subtitle],
        {
          autoAlpha: 0,
          y: 15,
          ease: "power1.easeOut",
          rotation: 0.01,
          stagger: 0.2,
        },
        "-=.5"
      )
  }, [])

  return (
    <section className="full-width-container section-hero">
      <div className="hero__bg-layer" ref={el => (bgLayer = el)}>
        <img ref={el => (bgCircle = el)} src={bgImgWhite} alt="" />
      </div>
      <div className="hero__img-container fixed-width-container">
        <div className="hero__img">
          <Img fluid={heroBgImg} alt="" />
        </div>
      </div>
      <div className="hero__content-container fixed-width-container">
        <div className="hero__content fixed-width-container">
          <h1
            ref={el => (title = el)}
            className="heading-primary hero__heading"
          >
            {heroTitle}
          </h1>
          <p ref={el => (subtitle = el)} className="hero__info">
            {heroSubtitle}
          </p>
        </div>
      </div>
      <div className="hero__bg-img full-width-container">
        <BackgroundImage
          fluid={bgImg}
          imgStyle="background-position: top"
        ></BackgroundImage>
      </div>
    </section>
  )
}

export default Hero
